import React from 'react';
import { useSelector } from 'react-redux';
import FooterComponent from '@rambler-components/footer';
import type { MenuLinkType, SocialLinkType } from '@rambler-components/footer';
import '@rambler-components/footer/styles.css';
import { createMenuLinks, socialLinks } from 'config/constants/footer';

function Footer() {
  const { isMobile } = useSelector((state: IAppState) => state.runtime);

  return (
    <FooterComponent
      type="white"
      socialLinks={socialLinks as SocialLinkType[]}
      menuLinks={createMenuLinks(isMobile) as MenuLinkType[]}
    />
  );
}

export default Footer;
