import React, {
  Fragment,
  useRef,
  useEffect,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router';
import cn from 'classnames';

import { format } from 'date-fns';
import dateAdd from 'date-fns/add';

import InputDate from 'common/components/InputDate';
import Button from '@rambler-components/button';

import { ICardProps } from 'Card';

import { YANDEX_METRICS } from 'config/constants/counters';
import { GENDER } from 'config/constants/gender';
import { SIGN } from 'config/constants/sign';

import Select from '@rambler-components/select';

import { sendAccountData } from 'common/redux/account';

import { getSignByDate } from 'common/utils/signByDate';

import YandexEvent from 'utils/counters/YandexEvent';

import { useTop100Context } from 'common/contexts/top100Context';

import { getSizeStyle, getTop100, Size } from './index';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.account,
  ],
  (
    isMobile,
    account,
  ) => ({
    isMobile,
    account,
  }),
);

interface ICardFormProps {
  form: Extract<
    ICardProps['form'],
    'user_date_of_birth'
    | 'user_date_gender'
    | 'horoscope_date_common'
    | 'sign_for_date'
    | 'moon_by_date'
    | 'moon_day_dropdown'
    | 'sun_by_date'
    | 'personal_taro_today'
  >
  link?: ICardProps['link']
}

function UserForm({
  form,
  link,
}: ICardFormProps) {
  const {
    top100Prefix,
  } = useTop100Context();
  const dispatch = useDispatch();
  const history = useHistory();
  const isAccountSignChanged = useRef(false);
  const isAccountSignAndGenderChanged = useRef(false);
  const [dateValue, setDateValue] = useState('');
  const [genderValue, setGenderValue] = useState<GENDER>();
  const [selectValue, setSelectValue] = useState();
  const {
    isMobile,
    account,
  } = useSelector(selectData);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    setIsButtonClicked(false);
  }, [dateValue, genderValue, selectValue]);

  useEffect(() => {
    if (isAccountSignChanged.current && dateValue && account.sign && link) {
      isAccountSignChanged.current = false;
      const signs = Object.keys(SIGN).join('|');
      const re = new RegExp(`(${signs})`, 'g');

      if (re.test(link.link)) {
        history.push(link.link.replace(re, account.sign));
      } else {
        history.push(`/${account.sign}/`);
      }
    }

    if (isAccountSignAndGenderChanged.current && dateValue && genderValue && account.sign) {
      isAccountSignAndGenderChanged.current = false;

      history.push(`/${account.sign}/${genderValue === GENDER.female ? 'woman' : 'man'}/`);
    }
  }, [account.sign]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkUserSign = () => {
    if (dateValue) {
      dispatch(sendAccountData({
        ...account,
        birthday: dateValue,
      }));

      // Отправляем только если ранее не было даты рождения
      if (!account.birthday) {
        (new YandexEvent(YANDEX_METRICS.COMMON)).send({
          type: 'reachGoal',
          data: 'lenta_birthday_input',
        });
      }

      isAccountSignChanged.current = true;
    }
  };

  const checkUserSignAndGender = () => {
    if (dateValue && genderValue) {
      dispatch(sendAccountData({
        ...account,
        birthday: dateValue,
        gender:   genderValue,
      }));

      // Отправляем только если ранее не было даты рождения
      if (!account.birthday) {
        (new YandexEvent(YANDEX_METRICS.COMMON)).send({
          type: 'reachGoal',
          data: 'lenta_birthday_input',
        });
      }

      isAccountSignAndGenderChanged.current = true;
    }
  };

  const formInput = (
    placeholder: string,
    top100Tail: string,
    value?: string,
    status?: 'error' | 'warning' | 'success',
    onChange?: any,
    bottomBound?: string,
    topBound?: string,
    calendarInitDate?: string,
  ) => (
    <InputDate
      className={cn(
        s.input,
        isMobile && s.inputMobile,
      )}
      placeholder={placeholder}
      isMobile={isMobile}
      type="border"
      value={value}
      status={status}
      onChange={onChange}
      min={bottomBound}
      max={topBound}
      calendarInitDate={calendarInitDate}
      {...getTop100(isMobile, top100Prefix, form, top100Tail || placeholder)}
    />
  );

  const formSelect = (
    values: {
      label: string
      value: string
    }[],
    placeholder: string,
    size: Size = 'normal',
    status?: 'success' | 'warning' | 'error',
    value?: any,
    onChange?: any,
  ) => (
    <Select
      className={cn(
        s.select,
        getSizeStyle(isMobile, size),
      )}
      placeholder={placeholder}
      value={value || null}
      zIndex={200}
      type="border"
      status={status}
      onChange={onChange || null}
      options={values}
    />
  );

  const formButton = (
    text: string,
    textMobile: string,
    onClick?: any,
    top100Tail?: string,
  ) => (
    <Button
      className={cn(
        s.button,
        isMobile && s.buttonMobile,
      )}
      onClick={onClick || null}
      {...getTop100(isMobile, top100Prefix, form, top100Tail || 'button')}
    >
      {isMobile ? textMobile : text}
    </Button>
  );

  const formTypes: Record<ICardFormProps['form'], JSX.Element[]> = {
    user_date_of_birth: [
      formInput(
        'Дата рождения',
        'birth_date',
        dateValue,
        (isButtonClicked && !dateValue) ? 'error' : undefined,
        setDateValue,
      ),
      formButton(
        'Показать для знака',
        'Показать',
        () => {
          setIsButtonClicked(true);
          if (dateValue) {
            checkUserSign();
          }
        },
      ),
    ],
    user_date_gender: [
      formInput(
        'Дата рождения',
        'birth_date',
        dateValue,
        (isButtonClicked && !dateValue) ? 'error' : undefined,
        setDateValue,
      ),
      formSelect(
        [
          {
            label: 'Мужской',
            value: GENDER.male,
          },
          {
            label: 'Женский',
            value: GENDER.female,
          },
        ],
        'Ваш пол',
        'normal',
        (isButtonClicked && !genderValue) ? 'error' : undefined,
        genderValue,
        setGenderValue,
      ),
      formButton(
        'Показать',
        'Показать',
        () => {
          setIsButtonClicked(true);
          if (dateValue && genderValue) {
            checkUserSignAndGender();
          }
        },
      ),
    ],
    horoscope_date_common: [
      formInput(
        'Дата',
        'date_pick',
        dateValue,
        (isButtonClicked && !dateValue) ? 'error' : undefined,
        setDateValue,
        '2003-08-01',
        format(dateAdd(new Date(), { days: 1 }), 'yyyy-MM-dd'),
      ),
      formButton(
        'Показать',
        'Показать',
        () => {
          setIsButtonClicked(true);
          if (dateValue) {
            history.push(`/${dateValue}/`);
          }
          checkUserSignAndGender();
        },
        `result_button${dateValue ? `::/${dateValue}/` : ''}`,
      ),
    ],
    sign_for_date: [
      formInput(
        'Дата рождения',
        'birth_date',
        dateValue,
        (isButtonClicked && !dateValue) ? 'error' : undefined,
        setDateValue,
        undefined,
        format(new Date(), 'yyyy-MM-dd'),
      ),
      formButton(
        'Показать знак',
        'Показать знак',
        () => {
          setIsButtonClicked(true);
          if (dateValue) {
            history.push(`/${getSignByDate(dateValue)}/description/`);
          }
          checkUserSignAndGender();
        },
        `${getSignByDate(dateValue) ? `/${getSignByDate(dateValue)}` : ''}/description/`,
      ),
    ],
    moon_by_date: [
      formInput(
        'Дата',
        'moon_date',
        dateValue,
        (isButtonClicked && !dateValue) ? 'error' : undefined,
        setDateValue,
        '2007-01-02',
        `${new Date().getFullYear()}-12-31`,
        format(new Date(), 'yyyy-MM-dd'),
      ),
      formButton(
        'Показать прогноз',
        'Показать прогноз',
        () => {
          setIsButtonClicked(true);
          if (dateValue) {
            const date = dateValue || format(new Date(), 'yyyy-MM-dd');
            history.push(`/moon/calendar/${date}/`);
          }
        },
      ),
    ],
    moon_day_dropdown: [
      formSelect(
        [...Array(30)].map((_, index) => {
          const day = index + 1;

          return {
            label: `${day} лунный день`,
            value: `${day}`,
          };
        }),
        'Выберите лунный день',
        'normal',
        (isButtonClicked && !selectValue) ? 'error' : undefined,
        selectValue,
        setSelectValue,
      ),
      formButton(
        'Показать для дня',
        'Показать для дня',
        () => {
          setIsButtonClicked(true);
          if (selectValue) {
            history.push(`/moon/day/${selectValue}/`);
          }
        },
      ),
    ],
    sun_by_date: [
      formInput(
        'Дата',
        'sun_date',
        dateValue,
        (isButtonClicked && !dateValue) ? 'error' : undefined,
        setDateValue,
        '2007-01-02',
        `${new Date().getFullYear()}-12-31`,
        format(new Date(), 'yyyy-MM-dd'),
      ),
      formButton(
        'Показать прогноз',
        'Показать прогноз',
        () => {
          setIsButtonClicked(true);
          if (dateValue) {
            const date = dateValue || format(new Date(), 'yyyy-MM-dd');
            history.push(`/sun/calendar/${date}/`);
          }
        },
      ),
    ],
    personal_taro_today: [
      formInput(
        'Дата рождения',
        'birth_date',
        dateValue,
        (isButtonClicked && !dateValue) ? 'error' : undefined,
        setDateValue,
      ),
      formButton(
        'Рассчитать',
        'Рассчитать',
        () => {
          setIsButtonClicked(true);
          if (dateValue) {
            const today = format(new Date(), 'yyyy-MM-dd');
            const date = dateValue || format(new Date(), 'yyyy-MM-dd');
            history.push(`/personal/${today}/${date}/`);
          }
        },
      ),
    ],
  };

  if (!formTypes[form]) return null;

  return (
    <>
      {formTypes[form].map((item: JSX.Element, index: number) => {
        const key = `CardForm-${form}-${index}`;

        return (
          <Fragment key={key}>
            {item}
          </Fragment>
        );
      })}
    </>
  );
}

UserForm.defaultProps = {
  link: undefined,
};

export { UserForm };
