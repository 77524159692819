import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Typography } from 'common/components/Typography';
import YandexEvent from 'utils/counters/YandexEvent';

import { YANDEX_METRICS } from 'config/constants/counters';

import { EmojiCard, EmojiCardItemType } from './EmojiCard';
import { EmoForecast } from './EmoForecast';

import s from './styles.css';

interface IEmoWidget {
  className?: string
}

const CARDS = [
  {
    name:        'fine',
    icon:        'emoji-fine',
    title:       'У меня всё хорошо',
    textDataKey: 'everything_fine_text',
  },
  {
    name:        'doubt',
    icon:        'emoji-doubt',
    title:       'Пребываю в сомнениях',
    textDataKey: 'in_doubt_text',
  },
  {
    name:        'support',
    icon:        'emoji-support',
    title:       'Нужна поддержка',
    textDataKey: 'relationship_difficulties_text',
  },
  {
    name:        'event',
    icon:        'emoji-event',
    title:       'Жду важное событие',
    textDataKey: 'important_event_text',
  },
];

const SPLIT_AS_IS = 50;
const SPLIT_TEST_1 = 51;
const SPLIT_TEST_2 = 52;

function EmoWidget({ className }: IEmoWidget) {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);
  const widgetData = useSelector((state: IAppState) => state.emojiWidget.data);
  const currentPath = useSelector((state: IAppState) => state.runtime.path);
  const widgetSplitValue = useSelector((state: IAppState) => state.runtime.splits.emoji_widget
    || SPLIT_AS_IS);

  const [forecastData, setForecastData] = useState<EmojiCardItemType | null>(null);

  const isRender = widgetSplitValue === SPLIT_AS_IS ? false : Object.keys(widgetData)?.length
  && currentPath === `/${widgetData?.sign}/`;

  useEffect(() => {
    if (isRender) {
      (new YandexEvent(YANDEX_METRICS.COMMON)).send({
        type: 'reachGoal',
        data: 'emo_widget_show',
      });
    }
  }, [isRender]);

  if (!isRender) {
    return null;
  }

  const splitName = widgetSplitValue === SPLIT_TEST_1 ? 'emo_horo_split1' : 'emo_horo_split2';
  const withSpecial = widgetSplitValue === SPLIT_TEST_2 && widgetData.is_special;

  const cardHandleClick = (item: EmojiCardItemType) => {
    setForecastData(item);
    (new YandexEvent(YANDEX_METRICS.COMMON)).send({
      type: 'reachGoal',
      data: 'emo_widget_click',
    });
  };

  return (
    <div className={cn(
      s.root,
      isMobile && s.mobile,
      className,
    )}
    >
      {!forecastData && (
      <div className={s.emoji}>
        <Typography
          variant="h3"
          className={s.emojiTitle}
        >
          Получите совет от звёзд, выбрав своё настроение
        </Typography>
        <div className={s.cards}>
          {CARDS.map((item: EmojiCardItemType) => (
            <EmojiCard
              key={item.name}
              item={item}
              splitName={splitName}
              onClick={cardHandleClick}
            />
          ))}
        </div>
      </div>
      )}
      {forecastData && (
        <EmoForecast
          splitName={splitName}
          withSpecial={withSpecial}
          widgetData={widgetData}
          forecastData={forecastData}
        />
      )}
    </div>
  );
}

EmoWidget.defaultProps = {
  className: '',
};

export { EmoWidget };
